.scale {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 35px;
}

.zoomButtons {
  display: flex;
}

.zoom {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  background-color: var(--brandColor);
  color: var(--white);
}

.zoom:hover {
  color: var(--white);
}

.column {
  display: flex;
  flex-direction: column;
  width: 150px;
}

.scaleBar {
  margin-bottom: 5px;
  background: linear-gradient(
    to right,
    var(--light-grey-blue),
    var(--charting-purple-dark)
  );
  height: 10px;
}

.values {
  display: flex;
  justify-content: space-between;
  height: 15px;
  color: var(--gray);
  font-size: 14px;
}
